// @flow
// import styles
import style from "./style.module.scss";

// import libs
import React, {
    Fragment,
    useEffect,
    useState,
    useCallback,
    useMemo,
} from "react";
import { navigate } from "gatsby";
import classnames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

//import main menu icons
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import ListIcon from "@material-ui/icons/List";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

//import submenu icons
import InfoIcon from "@material-ui/icons/Info";
import AssignmentIcon from "@material-ui/icons/Assignment";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CommentIcon from "@material-ui/icons/Comment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import WorkIcon from "@material-ui/icons/Work";
import EmailIcon from "@material-ui/icons/Email";
import SmsIcon from "@material-ui/icons/Sms";

//beheerapp menu icons
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import ViewListIcon from "@material-ui/icons/ViewList";
import PeopleIcon from "@material-ui/icons/People";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LabelIcon from "@material-ui/icons/Label";
import LowPriorityIcon from "@material-ui/icons/LowPriority";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import FilterListIcon from "@material-ui/icons/FilterList";
import TitleIcon from "@material-ui/icons/Title";
import ListAltIcon from "@material-ui/icons/ListAlt";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import RecentActorsIcon from "@material-ui/icons/RecentActors";

//actionmenu icon
import PostAddIcon from "@material-ui/icons/PostAdd";

// own
import { usePermission, useMember, useUserRoleType } from "@hooks";
import { storage, EnvUtil } from "@utils";
import ActionMenu from "@components/Shared/ActionMenu";
import User from "../User";

import SubListRenderer from "./SubListRenderer";
import MenuRenderer from "./MenuRenderer";

const IconWithIconBadge = ({ MainIcon, BadgeIcon }: *) => (
    <Badge
        overlap="circle"
        badgeContent={
            <BadgeIcon fontSize="small" className={style.badgeIcon} />
        }
    >
        <MainIcon />
    </Badge>
);

/**
 * State type
 */

const isLocalhost = EnvUtil.isLocalhost();

/**
 * SideBar
 */
const SideBar = ({
    id,
    user,
    setUser,
    selectedAssignment,
    location,
    status,
    reference,
    inspectionId,
    clearRequestFlow,
    clearInspectionFlow,
    clearAssignment,
    cancelDashboardRequestsInProgress,
}: *) => {
    /**
     * Permissions
     */
    const hasPermissionToViewAllAssignments = usePermission(
        "navigation.assignments.all.read",
    );
    const hasPermissionToViewAdminPanel = usePermission(
        "navigation.admin.read",
    );
    const hasPermissionToViewAdvancedSearch = usePermission(
        "navigation.advancedSearch.read",
    );
    const hasPermissionToViewSearchIP = usePermission(
        "navigation.searchInspectionPoint.read",
    );
    const userIsMemberOfAssignment = useMember();

    const isUserExternal = useUserRoleType("ROLE_EXTERNAL");
    const isUserSecretariat = useUserRoleType("ROLE_SECRETARIAT");
    const isUserAdmin = useUserRoleType("ROLE_ADMIN");
    const isUserMiniAdmin = useUserRoleType("ROLE_MINIADMIN");

    /**
     * Sidebar items
     */
    const menuItems = useMemo(
        () => [
            {
                title: "Mijn dashboard",
                to: !isUserExternal
                    ? "/opdrachten/mijn-opdrachten"
                    : "/aanvragen/mijn-aanvragen",
                icon: <WorkIcon />,
                id: "mijn",
                onClick: () =>
                    !location.pathname.includes("mijn") &&
                    cancelDashboardRequestsInProgress(),
            },
            {
                title: "Alle opdrachten",
                to: "/opdrachten/opdrachten-team",
                icon: <ListIcon />,
                id: "team",
                hidden: !hasPermissionToViewAllAssignments,
                onClick: () =>
                    location.pathname.includes("mijn") &&
                    cancelDashboardRequestsInProgress(),
            },
            {
                title: "Inspectiepunt zoeken",
                to: "/inspectiepunt/zoek-inspectiepunt",
                icon: <MyLocationIcon />,
                id: "inspectiepunt/zoek-inspectiepunt",
                hidden: !hasPermissionToViewSearchIP,
            },

            {
                title: "Geavanceerd zoeken",
                isParent: true,
                id: "geavanceerd-zoeken-parent",
                hidden: !hasPermissionToViewAdvancedSearch,
                children: [
                    {
                        title: "Opdrachten",
                        to: "/geavanceerd-zoeken/opdrachten",
                        icon: (
                            <IconWithIconBadge
                                MainIcon={SearchIcon}
                                BadgeIcon={AssignmentIcon}
                            />
                        ),
                        id: "geavanceerd-zoeken/opdrachten",
                    },
                    {
                        title: "Aanvragen",
                        to: "/geavanceerd-zoeken/aanvragen",
                        icon: (
                            <IconWithIconBadge
                                MainIcon={SearchIcon}
                                BadgeIcon={PostAddIcon}
                            />
                        ),
                        id: "geavanceerd-zoeken/aanvragen",
                    },
                ],
            },
        ],
        [
            isUserExternal,
            hasPermissionToViewAdvancedSearch,
            hasPermissionToViewSearchIP,
            hasPermissionToViewAllAssignments,
            cancelDashboardRequestsInProgress,
            location?.pathname,
        ],
    );

    const subItems = useMemo(
        () => [
            {
                title: "Opdrachtdetails",
                id: "opdracht",
                to: "inspectie-details",
                icon: <InfoIcon />,
            },
            {
                title: "Inspectiepunten",
                id: "formulieren",
                to: "formulieren",
                hidden: isUserExternal,
                icon: <AssignmentIcon />,
            },
            {
                title: "Verslagen",
                id: "verslagen",
                to: "verslagen",
                icon: <FileCopyIcon />,
            },
            {
                title: "E-mails",
                id: "emails",
                to: "emails",
                icon: <EmailIcon />,
            },
            {
                title: "Reacties",
                id: "reactie",
                to: "reactie",
                icon: <CommentIcon />,
            },
            {
                title: "Interne Communicatie",
                id: "interne-communicatie",
                to: "interne-communicatie",
                hidden: isUserExternal,
                icon: <SmsIcon />,
            },
            {
                title: "Bijlagen",
                id: "bijlagen",
                to: "bijlagen",
                icon: <AttachFileIcon />,
                hidden: isUserExternal,
            },
        ],
        [isUserExternal],
    );

    const adminMenuItems = useMemo(
        () => [
            {
                title: "Nieuw inspectiepunt",
                to: "/beheer/nieuw-inspectiepunt",
                icon: <CreateNewFolderIcon />,
                id: "beheer/nieuw-inspectiepunt",
                hidden: !(isUserAdmin || isUserSecretariat || isUserMiniAdmin),
            },
            {
                title: "Redenen & subredenen",
                to: "/beheer/redenen",
                icon: <ViewListIcon />,
                id: "beheer/redenen",
                hidden: !isUserAdmin,
            },
            {
                title: "ZVG & Teams",
                to: "/beheer/zvg",
                icon: <PeopleIcon />,
                id: "beheer/zvg",
                hidden: !isUserAdmin,
            },
            {
                title: "E-mailsjablonen",
                to: "/beheer/emailsjablonen",
                icon: <MailOutlineIcon />,
                id: "beheer/emailsjablonen",
                hidden: !(isUserAdmin || isUserSecretariat),
            },

            {
                title: "Labels netwerkinspectie",
                to: "/beheer/labels-netwerkinspectie",
                icon: <LabelIcon />,
                id: "beheer/labels-netwerkinspectie",
                hidden: !isUserAdmin,
            },
            {
                title: "Keuzelijst uitstel OV/DV",
                to: "/beheer/keuzelijst-uitstel",
                icon: <LowPriorityIcon />,
                id: "beheer/keuzelijst-uitstel",
                hidden: !isUserAdmin,
            },
            {
                title: "Bulkopdrachten",
                to: "/beheer/bulkopdrachten",
                icon: <ViewCarouselIcon />,
                id: "beheer/bulkopdrachten",
                hidden: !isUserAdmin,
            },
            {
                title: "Vakantiedagen",
                to: "/beheer/vakantiedagen",
                icon: <EventBusyIcon />,
                id: "beheer/vakantiedagen",
                hidden: !isUserAdmin,
            },
            {
                title: "Filters",
                to: "/beheer/filters",
                icon: <FilterListIcon />,
                id: "beheer/filters",
                hidden: !isUserAdmin,
            },
            {
                title: "Verslagtitels",
                to: "/beheer/verslag-titels",
                icon: <TitleIcon />,
                id: "beheer/verslag-titels",
                hidden: !isUserAdmin,
            },
            {
                title: "Parameters openbaarheid",
                to: "/beheer/parameters-openbaarheid",
                icon: <ListAltIcon />,
                id: "beheer/parameters-openbaarheid",
                hidden: !isUserAdmin,
            },
            {
                title: "Versiebeheer",
                to: "/beheer/versiebeheer",
                icon: <DeviceHubIcon />,
                id: "beheer/versiebeheer",
                hidden: !isUserAdmin,
            },
            {
                title: "Gebruikersbeheer",
                to: "/beheer/gebruikers/interne-groepen",
                icon: <RecentActorsIcon />,
                id: "beheer/gebruikers/interne-groepen",
                hidden: !isUserAdmin,
            },
        ],
        [isUserAdmin, isUserMiniAdmin, isUserSecretariat],
    );

    //folding sidebar
    const [isFolded, toggleFolding] = useState(
        false || storage.get("isFolded") === "false",
    );

    //collapsing menu
    const [isMenuCollapsed, toggleCollapsing] = useState(
        false || storage.get("isMenuCollapsed") === "false",
    );
    //collapsing admin panel
    const [isAdminMenuCollapsed, toggleAdminMenu] = useState(
        false || storage.get("isAdminMenuCollapsed") === "false",
    );
    const [activeItem, setActiveItem] = useState(undefined);

    /**
     * Set user role
     */
    const changeUser = (user: *) => {
        /* start localhost workaround */
        if (isLocalhost) {
            const setCookie = token => {
                document.cookie = `MDLR_IT=${token};path=/;max-age=31536000;SameSite=strict`;
            };
            user && typeof user === "object" && setCookie(user.token);
        }
        /* end localhost workaround */
        setUser(user);
    };

    /**
     * Get active menu item
     */
    const computeActiveItem = useCallback(items => {
        const active = items.reduce<any>((acc, curr: *) => {
            if (!acc && !curr.isParent && location.pathname.includes(curr.id))
                return curr;
            if (!acc && curr.isParent && curr.children)
                return curr.children.find(child =>
                    location.pathname.includes(child.id),
                );
            return acc;
        }, undefined);
        return active;
    }, []);

    /**
     *  Mark last stored active item on startup
     */
    useEffect(() => {
        if (location?.pathname) {
            const activeItem = location.pathname.includes("/beheer")
                ? computeActiveItem(adminMenuItems)
                : computeActiveItem(menuItems);
            setActiveItem(activeItem);
        }
    }, [
        location?.pathname,
        computeActiveItem,
        setActiveItem,
        adminMenuItems,
        menuItems,
    ]);

    useEffect(() => {
        if (
            location?.pathname &&
            !location.pathname.includes("/opdrachten") &&
            selectedAssignment
        ) {
            clearAssignment();
        }
    }, [location?.pathname, clearAssignment, selectedAssignment]);

    /**
     * Active (highlight) the current tab
     */
    const isActive = (page: string) => {
        return location && location.pathname.includes("/" + page);
    };

    /**
     * nagivate user to logout page
     */
    const logout = () => {
        window.location.href = `${window.location.origin}/api/auth/logout`;
    };

    /*
     * Mark active visit
     */
    const isActiveVisit = (visitId: string) =>
        location.pathname.includes(visitId);

    /*
     *   Start new inspection flow
     */
    const startNewInspection = () => {
        navigate("/inspectie/zoek-inspectiepunt");
        clearInspectionFlow && clearInspectionFlow();
        clearRequestFlow && clearRequestFlow();
    };

    /*
     *   Start new request flow
     */
    const startNewRequest = () => {
        navigate("/aanvragen/zoek-inspectiepunt");
        clearInspectionFlow && clearInspectionFlow();
        clearRequestFlow && clearRequestFlow();
    };

    /**
     * Render
     */

    return (
        <div className={style.root}>
            <Drawer
                variant="permanent"
                className={classnames(style.drawer, {
                    [style.folded]: !isFolded,
                })}
                classes={{
                    paper: classnames(style.paper, {
                        [style.folded]: !isFolded,
                    }),
                }}
            >
                <div>
                    {user && (
                        <User
                            id={`${id}-user`}
                            user={user}
                            isOpen={isFolded}
                            onLogout={() => logout()}
                            onChangeUser={changeUser}
                        />
                    )}
                    <List>
                        <ListItem
                            button
                            id={`${id}-sidebar-folding-button}`}
                            onClick={() => {
                                toggleFolding(!isFolded);
                                storage.set("isFolded", isFolded.toString());
                            }}
                            className={style.list}
                        >
                            <ListItemIcon>
                                {isFolded ? <CloseIcon /> : <MenuIcon />}
                            </ListItemIcon>
                        </ListItem>

                        <MenuRenderer
                            id={id}
                            title="Menu"
                            toggleCollapsing={() => {
                                toggleCollapsing(!isMenuCollapsed);
                                localStorage.setItem(
                                    "isMenuCollapsed",
                                    isMenuCollapsed.toString(),
                                );
                            }}
                            isMenuCollapsed={isMenuCollapsed}
                            items={menuItems}
                            activeItem={activeItem}
                            isFolded={isFolded}
                        />
                    </List>
                    {inspectionId && (
                        <Fragment>
                            <Divider />
                            <SubListRenderer
                                id={id}
                                isFolded={isFolded}
                                reference={reference}
                                subItems={subItems}
                                inspectionId={inspectionId}
                                isActive={isActive}
                                userIsMemberOfAssignment={
                                    userIsMemberOfAssignment ||
                                    isUserMiniAdmin ||
                                    isUserSecretariat ||
                                    isUserAdmin
                                }
                                userIsInternal={!isUserExternal}
                                assignment={selectedAssignment}
                                noFormStatuses={[]}
                                status={status}
                                isActiveVisit={isActiveVisit}
                            />
                        </Fragment>
                    )}

                    {hasPermissionToViewAdminPanel && (
                        <Fragment>
                            <Divider />
                            <MenuRenderer
                                id={`${id}-admin`}
                                title="Beheermenu"
                                toggleCollapsing={() => {
                                    toggleAdminMenu(!isAdminMenuCollapsed);
                                    localStorage.setItem(
                                        "isAdminMenuCollapsed",
                                        isAdminMenuCollapsed.toString(),
                                    );
                                }}
                                isMenuCollapsed={isAdminMenuCollapsed}
                                items={adminMenuItems}
                                activeItem={activeItem}
                                isFolded={isFolded}
                                className={style.adminMenu}
                            />
                        </Fragment>
                    )}
                </div>

                <div
                    className={classnames({
                        [style.addBtnFolded]: !isFolded,
                    })}
                >
                    {isFolded && (
                        <Box className={style.createButtonsWrapper}>
                            <Button
                                id={`${id}-btnCreateRequest`}
                                variant="text"
                                color="primary"
                                onClick={() => startNewRequest()}
                                startIcon={<AddIcon />}
                                fullWidth
                            >
                                Inspectieaanvraag
                            </Button>
                            <Button
                                id={`${id}-btnCreateInspection`}
                                variant="contained"
                                color="primary"
                                onClick={() => startNewInspection()}
                                startIcon={<AddIcon />}
                                hidden={
                                    !hasPermissionToViewAllAssignments ||
                                    isUserExternal
                                }
                                fullWidth
                            >
                                Inspectieopdracht
                            </Button>
                        </Box>
                    )}
                    <Box classnames={style.createButtonsWrapper}>
                        {!isFolded && (
                            <ActionMenu
                                id={`${id}-actionmenu-add-assignment-or-request`}
                                items={[
                                    {
                                        text: "Nieuwe inspectieopdracht",
                                        icon: <AssignmentIcon />,
                                        action: () => startNewInspection(),
                                        hidden: !hasPermissionToViewAllAssignments,
                                    },
                                    {
                                        text: "Nieuwe inspectieaanvraag",
                                        icon: <PostAddIcon />,
                                        action: () => startNewRequest(),
                                    },
                                ]}
                                icon={<AddIcon />}
                            />
                        )}
                        <Divider />
                        <Box mt={3}>
                            <a
                                href="/Handleiding-Modular-2.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                id={`${id}-handleiding`}
                                title="Handleiding"
                            >
                                {isFolded ? (
                                    <Button
                                        id={`${id}-btnHelp`}
                                        variant="text"
                                        color="primary"
                                        startIcon={<HelpOutlineIcon />}
                                        fullWidth
                                    >
                                        Handleiding
                                    </Button>
                                ) : (
                                    <IconButton
                                        edge="end"
                                        aria-label="Handleiding raadplagen"
                                    >
                                        <HelpOutlineIcon />
                                    </IconButton>
                                )}
                            </a>
                        </Box>
                    </Box>
                </div>
            </Drawer>
        </div>
    );
};

export default SideBar;
